






















































































import Vue from "vue";
import Search from "@/components/Search.vue";
import { Component, Ref } from "vue-property-decorator";
@Component({
  components: {
    Search,
  },
})
export default class Chat extends Vue {
  titlePage = "Cake Engine Breaking Down";
  filename: File | null = null;

  @Ref() fileAttach!: HTMLElement;

  getImage() {
    if (this.fileAttach) {
      this.fileAttach.click();
    }
  }
}
